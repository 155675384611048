import React from "react";
import SectionVideo from "../../components/LandingPageComponents/SectionVideo";
//import SectionPreSale from "../../components/LandingPageComponents/SectionPreSale";
import SectionReward from "../../components/LandingPageComponents/SectionReward";
import SectionGenieVerse from "../../components/LandingPageComponents/SectionGenieVerse";
import SectionMeetPointGenieApp from "../../components/LandingPageComponents/SectionMeetPointGenieApp";
import SectionToken from "../../components/LandingPageComponents/SectionToken";
import SectionRoadmap from "../../components/LandingPageComponents/SectionRoadmap";
import SectionTeam from "../../components/LandingPageComponents/SectionTeam";
import SectionFaqs from "../../components/LandingPageComponents/SectionFaqs";
import SectionContact from "../../components/LandingPageComponents/SectionContact";

const LandingPage = () => {
  return (
    <>
      <main className="nk-pages">
        <SectionVideo />
        {/*<SectionPreSale /> */}
        <SectionReward />
        <SectionGenieVerse />
        <SectionMeetPointGenieApp />
        <SectionToken />
        <SectionRoadmap />
        <SectionTeam />
        <SectionFaqs />
        <SectionContact />
      </main>
    </>
  );
};

export default LandingPage;
