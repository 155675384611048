import { React } from "react";
import Gfx from "../../assets/front-imgs/light/gfx-z-a.png";

const SectionReward = () => {
  return (
    <>
      <section
        className="section mask-c-blend-dark bg-theme-dark-alt tc-light ov-h"
        id="ico"
      >
        <div className="container">
          <div className="nk-block nk-block-text-wrap">
            <div className="row align-items-center justify-content-center justify-content-md-between gutter-vr-30px">
              <div className="col-mb-9 col-sm-7 col-md-8 col-lg-8 order-md-last">
                <div
                  className="nk-block-img animated"
                  data-animate="fadeInUp"
                  data-delay=".1"
                >
                  <img src={Gfx} alt="app" />
                </div>
              </div>
              <div className="col-sm-10 col-md-4 text-center text-md-left">
                <div className="nk-block-text">
                  <h2
                    className="title animated custom_header_size"
                    data-animate="fadeInUp"
                    data-delay=".2"
                  >
                    Get rewarded from Your Social Media Activity <br />
                  </h2>
                  <p
                    className="lead animated custom_p_size"
                    data-animate="fadeInUp"
                    data-delay=".3"
                  >
                    Traditional Social Media platform make billions with your
                    content – We've created an ecosystem for content creators,
                    advertisers and general public to create and distribute the
                    wealth, backed by our utility token GenieCoin on the
                    Ethereum Blockchain and GeniePoints in our mobile apps.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SectionReward;
