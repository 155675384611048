import React from "react";
import { Typography, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import { useTheme } from "@emotion/react";

import styles from "./TitleBox.module.css";

const TitleBox = ({ account, apr, dailyEarning }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      <Box className={!isMobile ? styles.title : styles.titleMobile}>
        <Box className={!isMobile ? styles.content : styles.contentMobile}>
          {!isMobile ? (
            <>
              <Box
                color={"#ffffff"}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mb: 3,
                }}
              >
                <Typography
                  component="div"
                  m="auto"
                  sx={{
                    position: "relative",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="h4"
                    component="p"
                    sx={{
                      zIndex: 2,
                      cursor: "pointer",
                      color: "white",
                      fontWeight: "bolder",
                    }}
                  >
                    Point Genie Claiming and Staking dApp
                  </Typography>
                </Typography>
              </Box>
            </>
          ) : (
            <>
              <Box
                color={"#ffffff"}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mb: 3,
                }}
              >
                <Typography
                  component="div"
                  m="auto"
                  sx={{
                    position: "relative",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="h4"
                    component="p"
                    sx={{
                      zIndex: 2,
                      cursor: "pointer",
                      color: "white",
                      fontWeight: "bolder",
                    }}
                  >
                    Point Genie Claiming
                  </Typography>
                </Typography>
              </Box>
              <Box
                color={"#ffffff"}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mb: 3,
                }}
              >
                <Typography
                  component="div"
                  m="auto"
                  sx={{
                    position: "relative",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="h4"
                    component="p"
                    sx={{
                      zIndex: 2,
                      cursor: "pointer",
                      color: "white",
                      fontWeight: "bolder",
                    }}
                  >
                    And Staking dApp
                  </Typography>
                </Typography>
              </Box>
            </>
          )}
          <Box
            color={"#ffffff"}
            sx={{
              display: "flex",
              alignItems: "center",
              mt: !isMobile ? 3 : 7,
              mb: 3,
            }}
          >
            <Typography
              component="div"
              m="auto"
              sx={{
                position: "relative",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography
                component="p"
                sx={{
                  zIndex: 2,
                  cursor: "pointer",
                  color: "white",
                  fontSize: "12px",
                  background: "rgba(0, 0, 0, .4)",
                  padding: "11px 33px 11px 33px",
                  borderRadius: "40px",
                }}
              >
                {account}
              </Typography>
            </Typography>
          </Box>

          {!isMobile ? (
            <>
              <Box
                color={"#ffffff"}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mb: 3,
                }}
              >
                <Typography
                  component="div"
                  m="auto"
                  sx={{
                    position: "relative",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="h6"
                    component="p"
                    sx={{
                      zIndex: 2,
                      cursor: "pointer",
                      color: "white",
                    }}
                  >
                    Convert your GeniePoint to Pointgenie Coin.
                  </Typography>
                </Typography>
              </Box>
            </>
          ) : (
            <>
              <Box
                color={"#ffffff"}
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography
                  component="div"
                  m="auto"
                  sx={{
                    position: "relative",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="h6"
                    component="p"
                    sx={{
                      zIndex: 2,
                      cursor: "pointer",
                      color: "white",
                    }}
                  >
                    Convert your GeniePoint to
                  </Typography>
                </Typography>
              </Box>
              <Box
                color={"#ffffff"}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mb: 3,
                }}
              >
                <Typography
                  component="div"
                  m="auto"
                  sx={{
                    position: "relative",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="h6"
                    component="p"
                    sx={{
                      zIndex: 2,
                      cursor: "pointer",
                      color: "white",
                    }}
                  >
                    Pointgenie Coin.
                  </Typography>
                </Typography>
              </Box>
            </>
          )}

          <Box
            color={"#ffffff"}
            sx={{
              display: "flex",
              alignItems: "center",
              mb: 1,
            }}
          >
            <Typography
              component="div"
              m="auto"
              sx={{
                position: "relative",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography
                variant="h8"
                component="p"
                sx={{
                  zIndex: 2,
                  cursor: "pointer",
                  color: "white",
                }}
              >
                Stake and get {apr}% (APY) - {dailyEarning}% Daily
              </Typography>
            </Typography>
          </Box>
          <Box
            color={"#ffffff"}
            sx={{
              display: "flex",
              alignItems: "center",
              mb: 1,
            }}
          >
            <Typography
              component="div"
              m="auto"
              sx={{
                position: "relative",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography
                variant="h8"
                component="p"
                sx={{
                  zIndex: 2,
                  cursor: "pointer",
                  color: "white",
                }}
              >
                Earnings
              </Typography>
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default TitleBox;
