import React, { Suspense } from "react";
import { useState } from "react";
import { useEffect } from "react";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import useCustomTheme from "./hooks/useCustomTheme";
import { ThemeProvider } from "@emotion/react";
import { ConfirmDialogProvider } from "react-mui-confirm";

import Layout from "./components/Layout/Layout";
import LandingPage from "./pages/LandingPage";
import ClaimingPage from "./pages/ClaimingPage";
import PackageDetail from "./components/MintingPageComponents/PackageDetail/PackageDetail";

function App() {
  const [darkMode, setDarkMode] = useState(true);
  const { customTheme } = useCustomTheme(darkMode);

  useEffect(() => {
    const theme = localStorage.getItem("theme");

    if (theme === "dark") {
      localStorage.setItem("theme", "dark");
      setDarkMode(true);
    } else if (theme === "light") {
      localStorage.setItem("theme", "light");
      setDarkMode(false);
    }
  }, [customTheme]);
  return (
    <ThemeProvider theme={customTheme}>
      <ConfirmDialogProvider>
        <div className="nk-wrap">
          <BrowserRouter>
            <Layout>
              <Suspense fallback={<div className="loading" />}>
                <Routes>
                  <Route path="/" element={<LandingPage />} />
                  <Route
                    path="/package-detail/:id"
                    element={<PackageDetail />}
                  />
                  <Route
                    path="/claiming"
                    element={<ClaimingPage darkMode={darkMode} />}
                  />

                  {/*<Redirect to="/error" />*/}
                </Routes>
              </Suspense>
            </Layout>
          </BrowserRouter>
        </div>
      </ConfirmDialogProvider>
    </ThemeProvider>
  );
}
export default App;
