import { React } from "react";

const SectionRoadmap = () => {
  return (
    <>
      <section
        className="section bg-theme tc-light section-connect"
        id="roadmap"
      >
        <div className="container ov-h">
          <div className="section-head text-center wide-auto-sm">
            <h2
              className="title title-s4 animated"
              data-animate="fadeInUp"
              data-delay=".1"
              title="Roadmap"
            >
              Our Roadmap
            </h2>
          </div>
        </div>

        <div className="nk-block ov-h">
          <div className="container">
            <div
              className="roadmap-list animated"
              data-animate="fadeInUp"
              data-delay=".2"
            >
              <div className="roadmap-item roadmap-item-sm roadmap-done">
                <div className="roadmap-innr">
                  <h6 className="roadmap-title roadmap-title-s2">
                    January 2016
                  </h6>
                  <p>
                    PointGenie LLC was founded in New Jersey and planning
                    started
                  </p>
                </div>
              </div>
              <div className="roadmap-item roadmap-done">
                <div className="roadmap-innr">
                  <h6 className="roadmap-title roadmap-title-s2">July 2020</h6>
                  <p>
                    Business Intelligence, iOS app and various products are
                    launched{" "}
                  </p>
                </div>
              </div>
              <div className="roadmap-item roadmap-item-lg">
                <div className="roadmap-innr">
                  <h6 className="roadmap-title roadmap-title-s2">
                    September 2021
                  </h6>
                  <p>Launched of mobile Apps</p>
                </div>
              </div>
              <div className="roadmap-item">
                <div className="roadmap-innr">
                  <h6 className="roadmap-title roadmap-title-s2">
                    October 2021
                  </h6>
                  <p>
                    ICO preparation &amp; Press Tour and Smart Contract creation
                  </p>
                </div>
              </div>
              <div className="roadmap-item roadmap-item-sm">
                <div className="roadmap-innr">
                  <h6 className="roadmap-title roadmap-title-s2">
                    November 2021
                  </h6>
                  <p>Start Token Pre-Sale Round</p>
                </div>
              </div>
              <div className="roadmap-item">
                <div className="roadmap-innr">
                  <h6 className="roadmap-title roadmap-title-s2">
                    January 2022
                  </h6>
                  <p>Round (1) Token sale Start</p>
                </div>
              </div>
              <div className="roadmap-item roadmap-item-lg">
                <div className="roadmap-innr">
                  <h6 className="roadmap-title roadmap-title-s2">
                    February 2022
                  </h6>
                  <p>Round (2) Token sale Start</p>
                </div>
              </div>
              <div className="roadmap-item">
                <div className="roadmap-innr">
                  <h6 className="roadmap-title roadmap-title-s2">March 2022</h6>
                  <p>Round (3) Token Sales Start</p>
                </div>
              </div>
              <div className="roadmap-item roadmap-item-sm">
                <div className="roadmap-innr">
                  <h6 className="roadmap-title roadmap-title-s2">April 2022</h6>
                  <p>Round (4) Token Sales Start</p>
                </div>
              </div>
              <div className="roadmap-item">
                <div className="roadmap-innr">
                  <h6 className="roadmap-title roadmap-title-s2"> May 2022</h6>
                  <p>ICO concludes and date for Airdorp is announced</p>
                </div>
              </div>
              <div className="roadmap-item roadmap-item-lg">
                <div className="roadmap-innr">
                  <h6 className="roadmap-title roadmap-title-s2">June 2022</h6>
                  <p>
                    GenieCoin Listing on DEFI and talking with Major exchanges
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SectionRoadmap;
