import { React } from 'react';
import EricImg from "../../assets/front-imgs/team/eric_1.jpg";
import KranthiImg from "../../assets/front-imgs/team/Kranthi.jpg";
import Bcolor from "../../assets/front-imgs/team/b-color.jpg";
import StevenImg from "../../assets/front-imgs/team/steven.jfif";
import CImg from "../../assets/front-imgs/team/c.jpg";
import AkashImg from "../../assets/front-imgs/team/akash.jpg";
import DColorImg from "../../assets/front-imgs/team/d-color.jpg";
import FernandoImg from "../../assets/front-imgs/team/Fernando1.jfif";
import EColorImg from "../../assets/front-imgs/team/e-color.jpg";
import ChristianImg from "../../assets/front-imgs/team/christian.jfif";
import FColorImg from "../../assets/front-imgs/team/f-color.jpg";
import JacobImg from "../../assets/front-imgs/team/jacob.jpg.jfif";
import GImg from "../../assets/front-imgs/team/g-color.jpg";

const SectionTeam = () => {
    return (
        <>
            <section className="section bg-theme-dark tc-light ov-h pdb-0" id="team">
                <div className="container">
                    <div className="section-head text-center wide-auto-sm tc-light">
                        <h2 className="title title-s4 animated" data-animate="fadeInUp" data-delay=".1" title="team">Powered by a team</h2>
                        <p className="animated" data-animate="fadeInUp" data-delay=".2">With over 70 years of combined experience, more than 50 projects developed and millions in assets managed we know what we are doing. </p>
                    </div>
                    <div className="nk-block nk-block-team-list team-list">
                        <div className="row justify-content-center">
                            <div className="col-lg-3 col-mb-6">
                                <div className="team team-s3 team-s3-alt animated" data-animate="fadeInUp" data-delay=".3">
                                    <div className="team-photo round-full team-photo-bg">
                                        <a href="/#team-popup-1" className="team-show content-popup" data-overlay="bg-theme-grad-alternet"> </a>
                                        <img src={EricImg} alt="team" className="round-full" />
                                    </div>
                                    <h5 className="team-name title title-sm">Eric Azcona</h5>
                                    <span className="team-position">CEO &amp; Co-founder</span>
                                    <ul className="team-social team-social-s2">
                                        <li><a href="https://www.linkedin.com/in/ericazcona1">
                                            <em className="fab fa-linkedin-in"></em></a>
                                        </li>
                               
                                    </ul>
                                </div>
                                
                                <div id="team-popup-1" className="team-popup mfp-hide">
                                    <a href="/#" title="Close" className="mfp-close">×</a>
                                    <div className="row align-items-start">
                                        <div className="col-md-6">
                                            <div className="team-photo">
                                                <img src={KranthiImg} alt="team" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="team-popup-info pl-md-3">
                                                <h3 className="team-name title title-lg pt-4">Kranthi Yadav</h3>
                                                <p className="team-position mb-1">CEO &amp; CIO and Co-founder </p>
                                                <ul className="team-social team-social-s2 mb-4">
                                                    <li><a href="/#"><em className="fab fa-facebook-f"></em></a></li>
                                                    <li><a href="/#"><em className="fab fa-linkedin-in"></em></a></li>
                                                </ul>
                                                <p>He is a great man to work Lorem ipsum dolor sit amet, consec tetur adipis icing elit. Simi lique, autem. </p>
                                                <p>Tenetur quos facere magnam volupt ates quas esse Sedrep ell endus mole stiae tates quas esse Sed repell endus molesti aela uda ntium quis quam iusto minima thanks.</p>
                                                <div className="progress-list">
                                                    <div className="progress-wrap">
                                                        <div className="progress-title">Blockchain 
                                                            <span className="progress-amount">85%</span>
                                                        </div>
                                                        <div className="progress-bar progress-bar-xs bg-black-10">
                                                            <div className="progress-percent bg-theme-grad-alternet" data-percent="85"></div>
                                                        </div>
                                                    </div>
                                                    <div className="progress-wrap">
                                                        <div className="progress-title">Decentralization <span className="progress-amount">68%</span></div>
                                                        <div className="progress-bar progress-bar-xs bg-black-10">
                                                            <div className="progress-percent bg-theme-grad-alternet" data-percent="68"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-mb-6">
                                <div className="team team-s3 team-s3-alt animated" data-animate="fadeInUp" data-delay=".4">
                                    <div className="team-photo round-full team-photo-bg">
                                        <a href="#team-popup-2" className="team-show content-popup" data-overlay="bg-theme-grad-alternet"> </a>
                                        <img src={KranthiImg} alt="team" className="round-full" />
                                    </div>
                                    <h5 className="team-name title title-sm">Kranthi Yadav</h5>
                                    <span className="team-position">CTO &amp; Co-founder</span>
                                    <ul className="team-social team-social-s2">
                                        <li>
                                            <a href="https://www.linkedin.com/in/kranthi-allaboina-75a550124/">
                                            <em className="fab fa-linkedin-in"></em></a>
                                        </li>
                                    </ul>
                                </div>
                                
                                <div id="team-popup-2" className="team-popup mfp-hide">
                                    <a href="/#" title="Close" className="mfp-close">×</a>
                                    <div className="row align-items-start">
                                        <div className="col-md-6">
                                            <div className="team-photo">
                                                <img src={Bcolor} alt="team" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="team-popup-info pl-md-3">
                                                <h3 className="team-name title title-lg pt-4">Jonathan Rios</h3>
                                                <p className="team-position mb-1">CTO &amp; Software Engineer</p>
                                                <ul className="team-social team-social-s2 mb-4">
                                                    <li><a href="/#"><em className="fab fa-facebook-f"></em></a></li>
                                                    <li><a href="/#"><em className="fab fa-linkedin-in"></em></a></li>
                                                </ul>
                                                <p>He is a great man to work Lorem ipsum dolor sit amet, consec tetur adipis icing elit. Simi lique, autem. </p>
                                                <p>Tenetur quos facere magnam volupt ates quas esse Sedrep ell endus mole stiae tates quas esse Sed repell endus molesti aela uda ntium quis quam iusto minima thanks.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-mb-6">
                                <div className="team team-s3 team-s3-alt animated" data-animate="fadeInUp" data-delay=".5">
                                    <div className="team-photo round-full team-photo-bg">
                                        <a href="#team-popup-3" className="team-show content-popup" data-overlay="bg-theme-grad-alternet"> </a>
                                        <img src={StevenImg} alt="team" className="round-full" />
                                    </div>
                                    <h5 className="team-name title title-sm">Steven J. Landzberg</h5>
                                    <span className="team-position">CFO</span>
                                    <ul className="team-social team-social-s2">
                                        <li>
                                            <a href="https://www.linkedin.com/in/steven-j-landzberg-9249b3106/">
                                            <em className="fab fa-linkedin-in"></em></a>
                                        </li>
                                    </ul>
                                </div>
                                <div id="team-popup-3" className="team-popup mfp-hide">
                                    <a href="/#" title="Close" className="mfp-close">×</a>
                                    <div className="row align-items-start">
                                        <div className="col-md-6">
                                            <div className="team-photo">
                                                <img src={CImg} alt="team" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="team-popup-info pl-md-3">
                                                <h3 className="team-name title title-lg pt-4">Jason Morales</h3>
                                                <p className="team-position mb-1">Sr.Backend developer</p>
                                                <ul className="team-social team-social-s2 mb-4">
                                                    <li><a href="/#"><em className="fab fa-facebook-f"></em></a></li>
                                                    <li><a href="/#"><em className="fab fa-linkedin-in"></em></a></li>
                                                </ul>
                                                <p>He is a great man to work Lorem ipsum dolor sit amet, consec tetur adipis icing elit. Simi lique, autem. </p>
                                                <p>Tenetur quos facere magnam volupt ates quas esse Sedrep ell endus mole stiae tates quas esse Sed repell endus molesti aela uda ntium quis quam iusto minima thanks.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-mb-6">
                                <div className="team team-s3 team-s3-alt animated" data-animate="fadeInUp" data-delay=".6">
                                    <div className="team-photo round-full team-photo-bg">
                                        <a href="#team-popup-4" className="team-show content-popup" data-overlay="bg-theme-grad-alternet"> </a>
                                        <img src={AkashImg} alt="team" className="round-full" />
                                    </div>
                                    <h5 className="team-name title title-sm">Akash Jindal</h5>
                                    <span className="team-position">Lead Mobile Developer</span>
                                    <ul className="team-social team-social-s2">
                                        <li><a href="https://www.linkedin.com/in/akash-jindal-80793369/">
                                            <em className="fab fa-linkedin-in"></em></a>
                                        </li>
                                    </ul>
                                </div>
                                <div id="team-popup-4" className="team-popup mfp-hide">
                                    <a href="/#" title="Close" className="mfp-close">×</a>
                                    <div className="row align-items-start">
                                        <div className="col-md-6">
                                            <div className="team-photo">
                                                <img src={DColorImg} alt="team" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="team-popup-info pl-md-3">
                                                <h3 className="team-name title title-lg pt-4">Joseph Jordan</h3>
                                                <p className="team-position mb-1">Software Engineer</p>
                                                <ul className="team-social team-social-s2 mb-4">
                                                    <li><a href="/#"><em className="fab fa-facebook-f"></em></a></li>
                                                    <li><a href="/#"><em className="fab fa-linkedin-in"></em></a></li>
                                                </ul>
                                                <p>He is a great man to work Lorem ipsum dolor sit amet, consec tetur adipis icing elit. Simi lique, autem. </p>
                                                <p>Tenetur quos facere magnam volupt ates quas esse Sedrep ell endus mole stiae tates quas esse Sed repell endus molesti aela uda ntium quis quam iusto minima thanks.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="nk-block nk-block-team-list">
                        <div className="section-head section-head-sm text-center wide-auto-sm">
                            <h2 className="title-lg-2 title-semibold animated" data-animate="fadeInUp" data-delay=".7">ADVISORS</h2>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-lg-3 col-mb-6">
                                <div className="team team-s3 team-s3-alt animated" data-animate="fadeInUp" data-delay=".8">
                                    <div className="team-photo round-full team-photo-bg">
                                        <a href="#team-popup-1" className="team-show content-popup" data-overlay="bg-theme-grad-alternet"> </a>
                                        <img src={FernandoImg} alt="team" className="round-full" />
                                    </div>
                                    <h5 className="team-name title title-sm">Fernando Duran</h5>
                                    <span className="team-position">Finance &amp; Marketing</span>
                                    <ul className="team-social team-social-s2">
                                        <li>
                                            <a href="https://www.linkedin.com/in/fernando-duran/">
                                                <em className="fab fa-linkedin-in"></em>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div id="team-popup-5" className="team-popup mfp-hide">
                                    <a href="/#" title="Close" className="mfp-close">×</a>
                                    <div className="row align-items-start">
                                        <div className="col-md-6">
                                            <div className="team-photo">
                                                <img src={EColorImg} alt="team" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="team-popup-info pl-md-3">
                                                <h3 className="team-name title title-lg pt-4">Louis Baker</h3>
                                                <p className="team-position mb-1">CEO &amp; Lead Blockchain </p>
                                                <ul className="team-social team-social-s2 mb-4">
                                                    <li><a href="/#"><em className="fab fa-facebook-f"></em></a></li>
                                                    <li><a href="/#"><em className="fab fa-linkedin-in"></em></a></li>
                                                </ul>
                                                <p>He is a great man to work Lorem ipsum dolor sit amet, consec tetur adipis icing elit. Simi lique, autem. </p>
                                                <p>Tenetur quos facere magnam volupt ates quas esse Sedrep ell endus mole stiae tates quas esse Sed repell endus molesti aela uda ntium quis quam iusto minima thanks.</p>
                                                <div className="progress-list">
                                                    <div className="progress-wrap">
                                                        <div className="progress-title">Blockchain <span className="progress-amount">85%</span></div>
                                                        <div className="progress-bar progress-bar-xs bg-black-10">
                                                            <div className="progress-percent bg-theme-grad-alternet" data-percent="85"></div>
                                                        </div>
                                                    </div>
                                                    <div className="progress-wrap">
                                                        <div className="progress-title">Decentralization <span className="progress-amount">68%</span></div>
                                                        <div className="progress-bar progress-bar-xs bg-black-10">
                                                            <div className="progress-percent bg-theme-grad-alternet" data-percent="68"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-mb-6">
                                <div className="team team-s3 team-s3-alt animated" data-animate="fadeInUp" data-delay=".9">
                                    <div className="team-photo round-full team-photo-bg">
                                        <a href="#team-popup-2" className="team-show content-popup" data-overlay="bg-theme-grad-alternet"> </a>
                                        <img src={ChristianImg} alt="team" className="round-full" />
                                    </div>
                                    <h5 className="team-name title title-sm">Christian Piña</h5>
                                    <span className="team-position">Financial &amp; Sales</span>
                                    <ul className="team-social team-social-s2">
                                        <li>
                                            <a href="https://www.linkedin.com/in/christian-pina-nyl/">
                                                <em className="fab fa-linkedin-in"></em>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div id="team-popup-6" className="team-popup mfp-hide">
                                    <a href="/#" title="Close" className="mfp-close">×</a>
                                    <div className="row align-items-start">
                                        <div className="col-md-6">
                                            <div className="team-photo">
                                                <img src={FColorImg} alt="team" /> 
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="team-popup-info pl-md-3">
                                                <h3 className="team-name title title-lg pt-4">Jonathan Rios</h3>
                                                <p className="team-position mb-1">CTO &amp; Software Engineer</p>
                                                <ul className="team-social team-social-s2 mb-4">
                                                    <li><a href="/#"><em className="fab fa-facebook-f"></em></a></li>
                                                    <li><a href="/#"><em className="fab fa-linkedin-in"></em></a></li>
                                                </ul>
                                                <p>He is a great man to work Lorem ipsum dolor sit amet, consec tetur adipis icing elit. Simi lique, autem. </p>
                                                <p>Tenetur quos facere magnam volupt ates quas esse Sedrep ell endus mole stiae tates quas esse Sed repell endus molesti aela uda ntium quis quam iusto minima thanks.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-mb-6">
                                <div className="team team-s3 team-s3-alt animated" data-animate="fadeInUp" data-delay="1">
                                    <div className="team-photo round-full team-photo-bg">
                                        <a href="#team-popup-3" className="team-show content-popup" data-overlay="bg-theme-grad-alternet"> </a>
                                        <img src={JacobImg} alt="team" className="round-full" />
                                    </div>
                                    <h5 className="team-name title title-sm">Jacob Tober</h5>
                                    <span className="team-position">Blockchain Advisor</span>
                                    <ul className="team-social team-social-s2">
                                        <li>
                                            <a href="https://www.linkedin.com/in/jacob-tober-71127b19b/">
                                                <em className="fab fa-linkedin-in"></em>
                                            </a>
                                        </li>                                     
                                    </ul>
                                </div>
                                <div id="team-popup-7" className="team-popup mfp-hide">
                                    <a href="/#" title="Close" className="mfp-close">×</a>
                                    <div className="row align-items-start">
                                        <div className="col-md-6">
                                            <div className="team-photo">
                                                <img src={GImg} alt="team" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="team-popup-info pl-md-3">
                                                <h3 className="team-name title title-lg pt-4">Jason Morales</h3>
                                                <p className="team-position mb-1">Sr.Backend developer</p>
                                                <ul className="team-social team-social-s2 mb-4">
                                                    <li><a href="/#"><em className="fab fa-facebook-f"></em></a></li>
                                                    <li><a href="/#"><em className="fab fa-linkedin-in"></em></a></li>
                                                </ul>
                                                <p>He is a great man to work Lorem ipsum dolor sit amet, consec tetur adipis icing elit. Simi lique, autem. </p>
                                                <p>Tenetur quos facere magnam volupt ates quas esse Sedrep ell endus mole stiae tates quas esse Sed repell endus molesti aela uda ntium quis quam iusto minima thanks.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default SectionTeam;