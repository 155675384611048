import { React } from "react";

const SectionFaqs = () => {
  return (
    <>
      <section className="section bg-theme tc-light pb-0" id="faqs">
        <div className="container">
          <div className="section-head text-center wide-auto-sm">
            <h2
              className="title title-s4 animated"
              data-animate="fadeInUp"
              data-delay=".1"
              title="FAQS"
            >
              Frequently Asked Question
            </h2>
            <p className="animated" data-animate="fadeInUp" data-delay=".2">
              Please find answers to the most common questions, feel free to
              contact us with the form below if you didn't find the answer to
              your question.
            </p>
          </div>

          <div className="nk-block">
            <div className="row justify-content-center">
              <div className="col-xl-8 col-lg-10">
                <ul
                  className="nav tab-nav tab-nav-line mgb-r animated"
                  data-animate="fadeInUp"
                  data-delay=".3"
                >
                  <li>
                    <a
                      className="active"
                      data-toggle="tab"
                      href="#general-questions-19"
                    >
                      General
                    </a>
                  </li>
                  <li>
                    <a data-toggle="tab" href="#ico-questions-19">
                      Pre-ICO &amp; ICO
                    </a>
                  </li>
                  <li>
                    <a data-toggle="tab" href="#tokens-sales-19">
                      Token
                    </a>
                  </li>
                  <li>
                    <a data-toggle="tab" href="#client-19">
                      NFT's
                    </a>
                  </li>
                  <li>
                    <a data-toggle="tab" href="#legal-19">
                      Legal
                    </a>
                  </li>
                </ul>
                <div
                  className="tab-content animated"
                  data-animate="fadeInUp"
                  data-delay=".4"
                >
                  <div
                    className="tab-pane fade show active"
                    id="general-questions-19"
                  >
                    <div className="accordion accordion-faq" id="faq-67">
                      <div className="accordion-item accordion-item-s4 bg-theme-alt">
                        <h5
                          className="accordion-title"
                          data-toggle="collapse"
                          data-target="#faq-67-1"
                        >
                          What is GenieVerse?
                          <span className="accordion-icon accordion-icon-s2"></span>
                        </h5>
                        <div
                          id="faq-67-1"
                          className="collapse show"
                          data-parent="#faq-67"
                        >
                          <div className="accordion-content">
                            <p>
                              Genie is a platform that connects Location-based
                              users, social media, NFT’s and blockchain in one
                              single ecosystem. In traditional social media
                              platforms stakeholders and owners make millions of
                              dollars with user-generated content and you the
                              creator receives NOTHING..
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item accordion-item-s4 bg-theme-alt">
                        <h5
                          className="accordion-title collapsed"
                          data-toggle="collapse"
                          data-target="#faq-67-2"
                        >
                          What is the problem you are solving?
                          <span className="accordion-icon accordion-icon-s2"></span>
                        </h5>
                        <div
                          id="faq-67-2"
                          className="collapse"
                          data-parent="#faq-67"
                        >
                          <div className="accordion-content">
                            <p>
                              Traditional Social media platforms make Billions
                              with User-Generated content and the creators
                              NOTHING. Are you unsure of what to explore this
                              weekend? Looking for local attractions nearby?
                              Want to check if that restaurant is worth a visit?
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item accordion-item-s4 bg-theme-alt">
                        <h5
                          className="accordion-title collapsed"
                          data-toggle="collapse"
                          data-target="#faq-67-3"
                        >
                          What is PointGenie App?
                          <span className="accordion-icon accordion-icon-s2"></span>
                        </h5>
                        <div
                          id="faq-67-3"
                          className="collapse"
                          data-parent="#faq-67"
                        >
                          <div className="accordion-content">
                            <p>
                              {" "}
                              It's a place finder app with stunning and
                              interesting features. It gives you all sorts of
                              details about nearby shopping malls, salons,
                              public places, restaurants, and things to do while
                              you're there.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item accordion-item-s4 bg-theme-alt">
                        <h5
                          className="accordion-title collapsed"
                          data-toggle="collapse"
                          data-target="#faq-67-4"
                        >
                          How do I make money?
                          <span className="accordion-icon accordion-icon-s2"></span>
                        </h5>
                        <div
                          id="faq-67-4"
                          className="collapse"
                          data-parent="#faq-67"
                        >
                          <div className="accordion-content">
                            <p>
                              PointGenie network rewards its users for having
                              fun and doing their normal social media activities
                              such as places check-in, posting, referring
                              friends and watching sponsored content.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="tab-pane fade" id="ico-questions-19">
                    <div className="accordion accordion-faq" id="faq-68">
                      <div className="accordion-item accordion-item-s4 bg-theme-alt">
                        <h5
                          className="accordion-title"
                          data-toggle="collapse"
                          data-target="#faq-68-1"
                        >
                          How can I Participate of this big event?
                          <span className="accordion-icon accordion-icon-s2"></span>
                        </h5>
                        <div
                          id="faq-68-1"
                          className="collapse show"
                          data-parent="#faq-68"
                        >
                          <div className="accordion-content">
                            <p>
                              There are multiples ways you can participate
                              First: Download the Pointgenie app, register for
                              free, do a check-in, start referring friends and
                              earn about ($30 USD) Second: To increase your
                              potential earnings, participate in one of sale
                              events you will have access to earn up to 200%
                              bonus and other special perks.{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item accordion-item-s4 bg-theme-alt">
                        <h5
                          className="accordion-title collapsed"
                          data-toggle="collapse"
                          data-target="#faq-68-2"
                        >
                          How long will the Pre-ICO and ICO sale last?
                          <span className="accordion-icon accordion-icon-s2"></span>
                        </h5>
                        <div
                          id="faq-68-2"
                          className="collapse"
                          data-parent="#faq-68"
                        >
                          <div className="accordion-content">
                            <p>
                              Once pre sale event is launched will last 30 days.
                              Following the ICO will last 4 Months 4 rounds of 1
                              Month each.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item accordion-item-s4 bg-theme-alt">
                        <h5
                          className="accordion-title collapsed"
                          data-toggle="collapse"
                          data-target="#faq-68-3"
                        >
                          How can I purchase the Token/Coins?
                          <span className="accordion-icon accordion-icon-s2"></span>
                        </h5>
                        <div
                          id="faq-68-3"
                          className="collapse"
                          data-parent="#faq-68"
                        >
                          <div className="accordion-content">
                            <p>
                              All the official information relating the sale
                              event is announced here, you can buy with Bitcoin,
                              Ethereum, BNB, USD dollar and USDT{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item accordion-item-s4 bg-theme-alt">
                        <h5
                          className="accordion-title collapsed"
                          data-toggle="collapse"
                          data-target="#faq-68-4"
                        >
                          When will airdrop and trading start?
                          <span className="accordion-icon accordion-icon-s2"></span>
                        </h5>
                        <div
                          id="faq-68-4"
                          className="collapse"
                          data-parent="#faq-68"
                        >
                          <div className="accordion-content">
                            <p>
                              Once the ICO and crowdfunding events concludes the
                              airdrops will start 30 days later. The liquidity
                              pool dates will be announce after very shortly,
                              but be aware that coins will be locked and
                              availability will start gradually to protect the
                              sustainability of the project.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="tab-pane fade" id="tokens-sales-19">
                    <div className="accordion accordion-faq" id="faq-69">
                      <div className="accordion-item accordion-item-s4 bg-theme-alt">
                        <h5
                          className="accordion-title"
                          data-toggle="collapse"
                          data-target="#faq-69-1"
                        >
                          What is the blockchain this token use?
                          <span className="accordion-icon accordion-icon-s2"></span>
                        </h5>
                        <div
                          id="faq-69-1"
                          className="collapse show"
                          data-parent="#faq-69"
                        >
                          <div className="accordion-content">
                            <p>
                              We are a ERC20 utility token that runs over the
                              Ethereum network, there are 7 billion pre-minted
                              tokens available and the total supply is
                              controlled by an algorithm and the total supply is
                              set to reach the maximum in 25 years.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item accordion-item-s4 bg-theme-alt">
                        <h5
                          className="accordion-title collapsed"
                          data-toggle="collapse"
                          data-target="#faq-69-2"
                        >
                          How are the token generated?
                          <span className="accordion-icon accordion-icon-s2"></span>
                        </h5>
                        <div
                          id="faq-69-2"
                          className="collapse"
                          data-parent="#faq-69"
                        >
                          <div className="accordion-content">
                            <p>
                              The users are the one who generates the token with
                              their daily activity on PointGenie app, there are
                              daily limit supply of tokens that can be minted on
                              the app. Once these limits are reached will
                              restart next day, the token availibilty will
                              depend in multiples factor to be determined by the
                              game mechanic.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item accordion-item-s4 bg-theme-alt">
                        <h5
                          className="accordion-title collapsed"
                          data-toggle="collapse"
                          data-target="#faq-69-3"
                        >
                          Why there are GeniePoints and GenieCoins?
                          <span className="accordion-icon accordion-icon-s2"></span>
                        </h5>
                        <div
                          id="faq-69-3"
                          className="collapse"
                          data-parent="#faq-69"
                        >
                          <div className="accordion-content">
                            <p>
                              GeniePoints are produced inherently in the
                              PointGenie App. The GeniePoints once reach a
                              certain amount and meet some criteria based on the
                              account type and time factor can be exchange for
                              GeniePoints on the portal and can be withdrawn to
                              the user wallet to exchange for fiat currency.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item accordion-item-s4 bg-theme-alt">
                        <h5
                          className="accordion-title collapsed"
                          data-toggle="collapse"
                          data-target="#faq-69-4"
                        >
                          Are there limits or restrictions?
                          <span className="accordion-icon accordion-icon-s2"></span>
                        </h5>
                        <div
                          id="faq-69-4"
                          className="collapse"
                          data-parent="#faq-69"
                        >
                          <div className="accordion-content">
                            <p>
                              As every case is difference there are some
                              measures to prevent abuse and to protect the
                              viability of the project and to protect the users
                              assets, certain actions will have limits and the
                              rules can be subject to change anytime without
                              previous notice.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="tab-pane fade" id="client-19">
                    <div className="accordion accordion-faq" id="faq-70">
                      <div className="accordion-item accordion-item-s4 bg-theme-alt">
                        <h5
                          className="accordion-title"
                          data-toggle="collapse"
                          data-target="#faq-70-1"
                        >
                          How are the NFT's integrated in the Platform?
                          <span className="accordion-icon accordion-icon-s2"></span>
                        </h5>
                        <div
                          id="faq-70-1"
                          className="collapse show"
                          data-parent="#faq-70"
                        >
                          <div className="accordion-content">
                            <p>
                              As we mentioned before every location
                              Longituted/latitude on the planet is unique,
                              GenieVerse allow users to purchase this locations
                              inside our GenieVerse Ecosystem which means the
                              owner of these locations DON'T hold any
                              co-ownership with the owner of property in the
                              real-world.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item accordion-item-s4 bg-theme-alt">
                        <h5
                          className="accordion-title collapsed"
                          data-toggle="collapse"
                          data-target="#faq-70-2"
                        >
                          Are the properties in GenieVerse shared with
                          real-world owner?
                          <span className="accordion-icon accordion-icon-s2"></span>
                        </h5>
                        <div
                          id="faq-70-2"
                          className="collapse"
                          data-parent="#faq-70"
                        >
                          <div className="accordion-content">
                            <p>
                              Properties in GenieVerse belongs to GenieVerse and
                              is a parallel dimension of the real-world, think
                              about like a Monopoly board where everything
                              happening in a board is happening there.
                              GenieVerse platform is not affiliated with any
                              metaverse as we are developing our own technology.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item accordion-item-s4 bg-theme-alt">
                        <h5
                          className="accordion-title collapsed"
                          data-toggle="collapse"
                          data-target="#faq-70-3"
                        >
                          How NFT's gets it's value?
                          <span className="accordion-icon accordion-icon-s2"></span>
                        </h5>
                        <div
                          id="faq-70-3"
                          className="collapse"
                          data-parent="#faq-70"
                        >
                          <div className="accordion-content">
                            <p>
                              As mentioned before, properties in GenieVerse are
                              powered by the activities from the real-world
                              interaction through the Pointgenie app, anytime a
                              user check-into a place generate GeniePoints and a
                              percentage of this is shared with NFT's owner like
                              the mechanic in Monopoly game.{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item accordion-item-s4 bg-theme-alt">
                        <h5
                          className="accordion-title collapsed"
                          data-toggle="collapse"
                          data-target="#faq-70-4"
                        >
                          Where can I purchase and sell the NFT's?
                          <span className="accordion-icon accordion-icon-s2"></span>
                        </h5>
                        <div
                          id="faq-70-4"
                          className="collapse"
                          data-parent="#faq-70"
                        >
                          <div className="accordion-content">
                            <p>
                              We are developing our own marketplace ecosystem
                              where PointGenie will create and sell the
                              properties, the creators will also be able to
                              share their piece of art with community, every
                              NFT's will be trackec in the blockchain and the
                              asset history would be public, anyone would be
                              able to see the earnings and the value gained over
                              time.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="tab-pane fade" id="legal-19">
                    <div className="accordion accordion-faq" id="faq-71">
                      <div className="accordion-item accordion-item-s4 bg-theme-alt">
                        <h5
                          className="accordion-title"
                          data-toggle="collapse"
                          data-target="#faq-71-1"
                        >
                          Where is this company from?
                          <span className="accordion-icon accordion-icon-s2"></span>
                        </h5>
                        <div
                          id="faq-71-1"
                          className="collapse show"
                          data-parent="#faq-71"
                        >
                          <div className="accordion-content">
                            <p>
                              PointGenie LLC was founder in the State of New
                              Jersey in 2016, the headquarter is located in
                              Miami Florida and another offices are set to open
                              in India and Estonia.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item accordion-item-s4 bg-theme-alt">
                        <h5
                          className="accordion-title collapsed"
                          data-toggle="collapse"
                          data-target="#faq-71-2"
                        >
                          Is GenieCoin a different company than PointGenie?
                          <span className="accordion-icon accordion-icon-s2"></span>
                        </h5>
                        <div
                          id="faq-71-2"
                          className="collapse"
                          data-parent="#faq-71"
                        >
                          <div className="accordion-content">
                            <p>
                              GenieCoin is set to be operating in Estonia under
                              their legal jurisdiction, GenieCoin is a separate
                              entity managed by PointGenie local due to
                              restriction and regulations in place in the United
                              States.{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item accordion-item-s4 bg-theme-alt">
                        <h5
                          className="accordion-title collapsed"
                          data-toggle="collapse"
                          data-target="#faq-71-3"
                        >
                          WWill any investment I do in the platform safe?
                          <span className="accordion-icon accordion-icon-s2"></span>
                        </h5>
                        <div
                          id="faq-71-3"
                          className="collapse"
                          data-parent="#faq-71"
                        >
                          <div className="accordion-content">
                            <p>
                              We are a company founded in United States and the
                              founders are people known, but with any investment
                              there are risks, the future can not be controlled
                              but we are taking measures in technology, finances
                              and planning to mitigate any risks that can emerge
                              suddenly.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item accordion-item-s4 bg-theme-alt">
                        <h5
                          className="accordion-title collapsed"
                          data-toggle="collapse"
                          data-target="#faq-71-4"
                        >
                          When will I be able to withdraw my token and sale?
                          <span className="accordion-icon accordion-icon-s2"></span>
                        </h5>
                        <div
                          id="faq-71-4"
                          className="collapse"
                          data-parent="#faq-71"
                        >
                          <div className="accordion-content">
                            <p>
                              Once the token sale finalized the company will
                              announce the airdrop start date, there will be
                              locking periods and the withdrawal access would be
                              based on the plans selected during the ICO and
                              crowdfunding campaign. More details will be
                              shared.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SectionFaqs;
