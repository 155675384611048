import { React } from "react";
import EDarkImg from "../../assets/front-imgs/dark/e-dark.png";
import FDarkImg from "../../assets/front-imgs/dark/f-dark.png";

const SectionToken = () => {
  return (
    <>
      <section
        className="section section-tokensale tc-light bg-theme-dark"
        id="token"
      >
        <div className="container">
          <div className="section-head text-center wide-auto-sm">
            <h2
              className="title title-s4 animated"
              data-animate="fadeInUp"
              data-delay=".1"
              title="TOKEN"
            >
              Token Sale
            </h2>
            <p className="animated" data-animate="fadeInUp" data-delay=".2">
              GenieCoin is the fuel which power our network, each GenieCoin is
              generated from our real-world app PointGenie and it's users.
              Stake, buy advertising credits, NFT's and more.
            </p>
          </div>
          <div className="nk-block nk-block-token mgb-m30">
            <div className="row align-items-center justify-content-between gutter-vr-50px">
              <div className="col-lg-6">
                <div className="row gutter-vr-30px">
                  <div className="col-sm-6">
                    <div
                      className="stage-info animated"
                      data-animate="fadeInUp"
                      data-delay=".3"
                    >
                      <h6 className="title title-s6 title-xs-s2">Start</h6>
                      <p>Jan 12, 2022 (9:00AM GMT)</p>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div
                      className="stage-info animated"
                      data-animate="fadeInUp"
                      data-delay=".4"
                    >
                      <h6 className="title title-s6 title-xs-s2">
                        Number of tokens for sale
                      </h6>
                      <p>600 Million GNE (8.5%)</p>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div
                      className="stage-info animated"
                      data-animate="fadeInUp"
                      data-delay=".5"
                    >
                      <h6 className="title title-s6 title-xs-s2">End</h6>
                      <p>May 2, 2022 (11:00AM GMT)</p>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div
                      className="stage-info animated"
                      data-animate="fadeInUp"
                      data-delay=".6"
                    >
                      <h6 className="title title-s6 title-xs-s2">
                        Tokens exchange rate
                      </h6>
                      <p>0.15 ETH = 50k GNE, 0.010 BTC = 50k GNE</p>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div
                      className="stage-info animated"
                      data-animate="fadeInUp"
                      data-delay=".7"
                    >
                      <h6 className="title title-s6 title-xs-s2">
                        Acceptable currencies
                      </h6>
                      <p>ETH, BNC, BTC, USDT, USD, EUR</p>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div
                      className="stage-info animated"
                      data-animate="fadeInUp"
                      data-delay=".8"
                    >
                      <h6 className="title title-s6 title-xs-s2">
                        Minimal transaction amount
                      </h6>
                      <p>0.00025 ETH/ 0.000021 BTC/ 0.0018 BNB/ 1000 USD</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-5">
                <div
                  className="token-status token-status-s5 bg-theme-alt round no-bd animated"
                  data-animate="fadeInUp"
                  data-delay=".9"
                >
                  <div className="token-box token-box-s3">
                    <div
                      className="countdown-s3 countdown-s4 countdown"
                      data-date="2021/08/10"
                    ></div>
                  </div>
                  <div className="token-action">
                    <a
                      className="btn btn-md btn-grad btn-grad-alternet btn-round"
                      href="/#"
                    >
                      Join &amp; Buy Token
                    </a>
                  </div>
                  <ul className="icon-list">
                    <li>
                      <em className="fab fa-bitcoin"></em>
                    </li>
                    <li>
                      <em className="fas fa-won-sign"></em>
                    </li>
                    <li>
                      <em className="fab fa-cc-visa"></em>
                    </li>
                    <li>
                      <em className="fab fa-cc-mastercard"></em>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="nk-block">
            <div className="row justify-content-center gutter-vr-40px">
              <div className="col-md-6 col-mb-8">
                <div className="single-chart">
                  <h3
                    className="mgb-l text-center title-lg animated"
                    data-animate="fadeInUp"
                    data-delay=".1"
                  >
                    Distribution <br />
                    of tokens
                  </h3>
                  <div
                    className="chart animated"
                    data-animate="fadeInUp"
                    data-delay=".2"
                  >
                    <img src={EDarkImg} alt="chart" />
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-mb-8">
                <div className="single-chart">
                  <h3
                    className="mgb-l text-center title-lg animated"
                    data-animate="fadeInUp"
                    data-delay=".3"
                  >
                    Use <br />
                    of proceeds
                  </h3>
                  <div
                    className="chart animated"
                    data-animate="fadeInUp"
                    data-delay=".4"
                  >
                    <img src={FDarkImg} alt="chart" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SectionToken;
