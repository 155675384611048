import { React } from "react";
//import { useNavigate } from "react-router-dom";

import MainBannerWebm from "../../assets/front-imgs/video/main-banner.webm";
import MainBannerMP4 from "../../assets/front-imgs/video/main-banner.mp4";
import MainBannerGif from "../../assets/front-imgs/video/main-banner.gif";

const SectionVideo = () => {
  // const navigate = useNavigate();

  return (
    <>
      <div className="nk-header page-header is-transparent is-sticky is-shrink is-dark">
        <div
          className="header-banner bg-theme-dark"
          style={{ background: "#050b19 !important" }}
        >
          <div className="nk-banner">
            <div className="banner banner-mask-fix banner-fs banner-single tc-light">
              <div className="banner-wrap ov-v">
                <div className="container">
                  <div className="row align-items-center justify-content-center justify-content-lg-between gutter-vr-30px">
                    <div className="col-lg-5">
                      <div className="banner-caption wide-auto text-center text-lg-left">
                        <div className="cpn-head mt-0">
                          <h1
                            className="title title-xl-2 animated"
                            data-animate="fadeInUp"
                            data-delay="1.25"
                          >
                            Welcome to GenieVerse
                          </h1>
                        </div>
                        <div className="cpn-text cpn-text-s1">
                          <p
                            className="lead animated"
                            data-animate="fadeInUp"
                            data-delay="1.35"
                          >
                            Connecting Location-Based, Social Media, NFT's and
                            Blockchain in one Ecosystem.
                          </p>
                          <p
                            className="lead animated"
                            data-animate="fadeInUp"
                            data-delay="1.35"
                          >
                            Get rewarded for having fun, Earn Crypto!
                          </p>
                        </div>
                        <div className="cpn-btns">
                          <ul
                            className="btn-grp animated"
                            data-animate="fadeInUp"
                            data-delay="1.45"
                          >
                            <li>
                              <a
                                href="https://crowd.pointgeniecoin.com/register"
                                className="btn btn-sm btn-grad btn-round"
                              >
                                Sign up to join
                              </a>
                            </li>
                            <li>
                              <a
                                href="#token"
                                className="btn btn-sm btn-grad btn-grad-alternet btn-round"
                              >
                                Token Distribution
                              </a>
                            </li>
                            {/*<li>
                              <button
                                className="btn btn-sm btn-grad btn-grad-alternet btn-round"
                                onClick={() => navigate("/mint")}
                              >
                                Mint
                              </button>
  </li>*/}
                          </ul>
                        </div>
                        <div className="cpn-social">
                          <ul className="social">
                            <li
                              className="animated"
                              data-animate="fadeInUp"
                              data-delay="1.5"
                            >
                              <a href="https://www.facebook.com/PointGenie/">
                                <em className="social-icon fab fa-facebook-f"></em>
                              </a>
                            </li>
                            <li
                              className="animated"
                              data-animate="fadeInUp"
                              data-delay="1.55"
                            >
                              <a href="https://twitter.com/pointgenie1">
                                <em className="social-icon fab fa-twitter"></em>
                              </a>
                            </li>
                            <li
                              className="animated"
                              data-animate="fadeInUp"
                              data-delay="1.75"
                            >
                              <a href="https://medium.com/@PointGenie">
                                <em className="social-icon fab fa-medium-m"></em>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-7 col-sm-9">
                      <video className="w-100" autoPlay loop muted playsInline>
                        <source src={MainBannerWebm} type="video/webm" />
                        <source src={MainBannerMP4} type="video/mp4" />
                        <img src={MainBannerGif} alt="main-banner" />
                      </video>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="nk-ovm mask-c-dark shape-v mask-contain-bottom"></div>
        </div>
      </div>
    </>
  );
};

export default SectionVideo;
