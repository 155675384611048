import React, { useState } from "react";

// From Material UI
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Backdrop,
  Button,
  Divider,
  Fade,
  IconButton,
  Modal,
  Stack,
  Typography,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";

// Icon
import { AiOutlineHeart, AiFillHeart } from "react-icons/ai";
import { HiOutlineClock, HiTemplate } from "react-icons/hi";
import { BsFillBookmarkFill, BsBookmark, BsChevronDown } from "react-icons/bs";

import { useParams } from "react-router-dom";

// Styles
import styles from "./packageDetail.module.css";

import Logo from "../../../assets/front-imgs/logo-white.png";

const PackageDetail = (props) => {
  const [openModal, setOpenModal] = React.useState(false);

  const [likeState, setLikeState] = useState(false);
  const [bookmarkState, setBookmarkState] = useState(false);

  const [showBidInput, setShowBidInput] = useState(false);

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const { id } = useParams(); // Read from url

  return (
    <>
      <div className="price_table mask-c-blend-dark bg-theme-dark-alt">
        <div
          className="container py-4 flex justify-center"
          style={{ marginTop: "100px", marginBottom: "100px", color: "whilte" }}
        >
          <Modal
            sx={{ zIndex: 500000 }}
            open={openModal}
            onClose={handleCloseModal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={openModal}>
              <Box bgcolor="#171c26" className={styles.modalStyleWeb}>
                <Box className={styles.modalBox}>
                  <Typography component="span" color="#ffffff" mt={0.5}>
                    <HiTemplate fontSize={"1.5rem"} />
                  </Typography>
                  <Typography color="#ffffff" variant="h6" mt={-0.2}>
                    Item Details
                  </Typography>
                </Box>
                <Typography
                  // Global classes
                  className="readMoreModalText"
                  variant="body2"
                  color="#ffffff"
                  lineHeight={2}
                  height="250px"
                  pr={2.5}
                  sx={{
                    overflowY: "auto",
                  }}
                >
                  This is Package {id}
                </Typography>
              </Box>
            </Fade>
          </Modal>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            gap={4}
          >
            <Box bgcolor="#171C26" className={styles.auctionMainContentBox}>
              <Box>
                <Typography variant="h6" color="#ffffff" mb={4}>
                  Package {id}
                </Typography>
                <Typography
                  variant="body2"
                  lineHeight={1.5}
                  mb={2}
                  textAlign="justify"
                >
                  This is Package {id}
                  <Button
                    variant="text"
                    sx={{ color: "#01D4FA", textTransform: "lowercase" }}
                    onClick={handleOpenModal}
                  >
                    ...READ_MORE
                  </Button>
                </Typography>
                <Accordion
                  className={styles.accordionStyles}
                  disableGutters
                  elevation={0}
                  sx={{
                    backgroundColor: "#171c26",
                  }}
                >
                  <AccordionSummary
                    expandIcon={
                      <BsChevronDown
                        fontSize={"1rem"}
                        color="#ffffff"
                        style={{ marginTop: "5px" }}
                      />
                    }
                  >
                    <Typography variant="h6" color="#ffffff" ml={-2}>
                      PROPERTIES
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Stack direction="row" spacing={2} ml={-2}>
                      <Box className={styles.propBox} bgcolor="#040404">
                        <Typography variant="button" color="#01D4FA">
                          Points
                        </Typography>
                        <Typography variant="body2" color="#ffffff">
                          1M
                        </Typography>
                      </Box>
                    </Stack>
                  </AccordionDetails>
                </Accordion>
                <Typography variant="h6" color="#FFFFFF" mb={2}>
                  PRICE
                </Typography>
                <Typography
                  variant="h5"
                  color="#ffffff.main"
                  mb={3}
                  fontWeight={500}
                >
                  1000 MATIC
                </Typography>
                <Divider className={styles.dividerStyle} />

                <Divider className={styles.dividerStyle} />
                <Box className={styles.auctionInfoBox}>
                  <Box className={styles.avatarBox}>
                    <Box>
                      <Avatar
                        sx={{
                          border: `2px solid ${"#ffffff"}`,
                        }}
                        src={Logo}
                        alt="test"
                      />
                    </Box>
                    <Stack direction="column">
                      <Typography variant="caption" gutterBottom color="gray">
                        CREATOR
                      </Typography>
                      <Typography
                        variant="caption"
                        gutterBottom
                        color="#ffffff.main"
                      >
                        creator
                      </Typography>
                    </Stack>
                  </Box>
                  <Box className={styles.avatarBox}>
                    <Box>
                      <Avatar
                        sx={{
                          border: `2px solid ${"#ffffff"}`,
                        }}
                        src={Logo}
                        alt="test"
                      />
                    </Box>
                    <Stack direction="column">
                      <Typography variant="caption" gutterBottom color="gray">
                        "OWNER"
                      </Typography>
                      <Typography
                        variant="caption"
                        gutterBottom
                        color="#ffffff.main"
                      >
                        owner
                      </Typography>
                    </Stack>
                  </Box>
                </Box>
                <Divider className={styles.dividerStyle} />
                <Box className={styles.auctionInteractionBox}>
                  <Stack direction="row" alignItems="center">
                    <IconButton>
                      <Typography component="span" color="#ffffff">
                        <HiOutlineClock className={styles.iconStyle} />
                      </Typography>
                    </IconButton>
                    <Typography
                      variant="caption"
                      component="span"
                      color="#ffffff"
                    >
                      HOURS_AGO
                    </Typography>
                  </Stack>
                  <Stack direction="row" alignItems="center" gap={2}>
                    <Box className={styles.loveIconBox}>
                      <IconButton onClick={() => setLikeState(!likeState)}>
                        {likeState ? (
                          <Typography component="span" color="#e23e58">
                            <AiFillHeart className={styles.iconStyle} />
                          </Typography>
                        ) : (
                          <Typography color="#ffffff" component="span">
                            <AiOutlineHeart className={styles.iconStyle} />
                          </Typography>
                        )}
                      </IconButton>
                      <Typography
                        variant="caption"
                        component="span"
                        color="#ffffff"
                      >
                        likes
                      </Typography>
                    </Box>
                    <Box>
                      <IconButton
                        onClick={() => setBookmarkState(!bookmarkState)}
                      >
                        {bookmarkState ? (
                          <Typography component="span" color="#01D4FA">
                            <BsFillBookmarkFill className={styles.iconStyle} />
                          </Typography>
                        ) : (
                          <Typography component="span" color="#ffffff">
                            <BsBookmark className={styles.iconStyle} />
                          </Typography>
                        )}
                      </IconButton>
                    </Box>
                  </Stack>
                </Box>
              </Box>
              <Box
                className={styles.footerButtonBox}
                onSubmit={(e) => e.preventDefault()}
                component="form"
              >
                {showBidInput ? (
                  <Box sx={{ position: "relative" }}>
                    <TextField
                      id="outlined-basic"
                      label="Place a bid"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{ input: { color: "#ffffff" } }}
                      color="secondary"
                      focused
                    />
                    <Typography
                      sx={{
                        position: "absolute",
                        px: "10px",
                        py: "4px",
                        borderRadius: "10px",
                        backgroundColor: "#ffffff",
                        color: "#040404",
                        top: "10px",
                        left: "130px",
                      }}
                      variant="button"
                      component="p"
                    >
                      Matic
                    </Typography>
                    <Box mt={3}>
                      <button className="btn btn-sm btn-grad btn-grad-alternet btn-round">
                        Place Your Bid
                      </button>
                    </Box>
                  </Box>
                ) : (
                  <button
                    className="btn btn-sm btn-grad btn-grad-alternet btn-round"
                    onClick={() => setShowBidInput(true)}
                  >
                    Place Your Bid
                  </button>
                )}
              </Box>
            </Box>
          </Box>
        </div>
      </div>
    </>
  );
};

export default PackageDetail;
