import { React } from "react";
//import { Typography, MenuItem, Menu } from "@mui/material";
//import { useNavigate } from "react-router-dom";

// Gradient buttons
//import { GradientButtonSecondary } from "../../Utils/GradientButtons/GradientButtons";

//import { FaWallet } from "react-icons/fa";
//import { BsChevronDown } from "react-icons/bs";
//import { TiCancel } from "react-icons/ti";

import Logo from "../../assets/front-imgs/logo-white.png";
import LogoSet from "../../assets/front-imgs/logo-white2x.png";
//import ConnectWalletPopUp from "../ConnectWalletPopup/ConnectWalletPopup";
//import MetaMaskIcon from "../../assets/icons/darkUIIcons/metaMaskIcon.svg";

const Header = () => {
  //const [walletAddress, setWalletAddress] = useState("");
  //const [openModal, setOpenModal] = useState(false);
  //const [anchorEl, setAnchorEl] = useState(null);

  //const handleOpenModal = () => setOpenModal(true);
  //const handleCloseModal = () => setOpenModal(false);
  //const openMenu = Boolean(anchorEl);

  //const navigate = useNavigate();

  /*const handleConnectWallet = async () => {
    handleCloseModal();
    if (typeof window.ethereum !== "undefined") {
      const accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
      });

      setWalletAddress(accounts[0]);
    }
  };*/

  /*const handleClickTrigger = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleDisconnectWallet = () => {
    if (walletAddress) {
      window.location.reload();
    }
  };*/

  return (
    <>
      <header
        className="nk-header page-header is-transparent is-sticky is-shrink is-dark"
        id="header"
      >
        <div className="header-main">
          <div className="header-container container">
            <div className="header-wrap">
              <div
                className="header-logo logo animated"
                data-animate="fadeInDown"
                data-delay=".65"
              >
                <a href="/" className="logo-link">
                  <img
                    className="logo-light"
                    src={Logo}
                    srcSet={LogoSet}
                    alt="logo"
                  />
                </a>
              </div>

              <div className="header-nav-toggle">
                <a
                  href={0}
                  className="navbar-toggle"
                  data-menu-toggle="example-menu-04"
                >
                  <div className="toggle-line">
                    <span></span>
                  </div>
                </a>
              </div>

              <div className="header-navbar header-navbar-s1">
                <nav className="header-menu" id="example-menu-04">
                  <ul
                    className="menu menu-s2 animated"
                    data-animate="fadeInDown"
                    data-delay=".75"
                  >
                    <li className="menu-item">
                      <a className="menu-link nav-link" href="#ico">
                        What is GenieCoin
                      </a>
                    </li>
                    <li className="menu-item">
                      <a className="menu-link nav-link" href="#token">
                        Token Sale
                      </a>
                    </li>
                    <li className="menu-item">
                      <a className="menu-link nav-link" href="#roadmap">
                        Roadmap
                      </a>
                    </li>
                    <li className="menu-item">
                      <a className="menu-link nav-link" href="#team">
                        Team
                      </a>
                    </li>

                    <li className="menu-item">
                      <a className="menu-link nav-link" href="#faqs">
                        Faqs
                      </a>
                    </li>
                    <li className="menu-item">
                      <a className="menu-link nav-link" href="/claiming">
                        dApp
                      </a>
                    </li>
                    <li className="menu-item">
                      <a className="menu-link nav-link" href="#contact">
                        Contact
                      </a>
                    </li>
                  </ul>
                  <ul
                    className="menu-btns animated"
                    data-animate="fadeInDown"
                    data-delay=".85"
                  >
                    <li>
                      <a
                        href="GenieCoinWhitepaper.pdf"
                        className="btn btn-rg btn-auto btn-outline btn-grad on-bg-theme btn-round"
                      >
                        <span>Whitepaper</span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://pointgenie.page.link/pointgeniecoin"
                        className="btn btn-rg btn-auto btn-outline btn-grad on-bg-theme btn-round"
                      >
                        <span>Try App</span>
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>

        {/*<ConnectWalletPopUp
          openModal={openModal}
          handleCloseModal={handleCloseModal}
          handleConnectWallet={handleConnectWallet}
          handleDisconnectWallet={handleDisconnectWallet}
          walletAddress={walletAddress}
        />

        <Menu
          id="basic-menu"
          PaperProps={{
            style: {
              backgroundColor: "#171c26",
              padding: "0 10px",
              borderRadius: "10px",
            },
          }}
          anchorEl={anchorEl}
          open={openMenu}
          onClose={handleCloseMenu}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          style={{ marginTop: "12px" }}
        >
          <MenuItem
            style={{
              width: "200px",
              backgroundColor: "#040404",
              paddingLeft: "2rem",
              gap: 15,
              borderRadius: "10px",
              marginBottom: "10px",
            }}
            onClick={handleDisconnectWallet}
          >
            <Typography component="span" color="#ffffff">
              <TiCancel />
            </Typography>
            <Typography color="#ffffff">DISCONNECT</Typography>
          </MenuItem>
        </Menu>*/}
      </header>
    </>
  );
};

export default Header;
