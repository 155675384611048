import { React } from "react";
import CellPhoneImg from "../../assets/front-imgs/cell-phone.png";

const SectionMeetPointGenieApp = () => {
  return (
    <>
      <section className="section bg-theme-dark-alt tc-light">
        <div className="container">
          <div className="section-head text-center wide-auto-sm tc-light">
            <div className="section-head-line">
              <span className="line-1"></span>
              <span className="line-2"></span>
              <span className="line-3"></span>
              <span className="line-4"></span>
              <span className="line-5"></span>
              <span className="line-6"></span>
              <span className="line-7"></span>
              <span className="line-8"></span>
            </div>
            <h2
              className="title title-s4 animated"
              data-animate="fadeInUp"
              data-delay=".1"
              title="APPS"
            >
              Meet the PointGenie App
            </h2>
            <p className="animated" data-animate="fadeInUp" data-delay=".2">
              Are you new in town or just looking for your next terrific
              experience? Looking recommendations of the best places around you?{" "}
            </p>
          </div>
          <div className="nk-block nk-block-text-wrap">
            <div className="row align-items-center justify-content-center justify-content-md-between gutter-vr-30px">
              <div className="col-mb-9 col-sm-7 col-md-8">
                <div
                  className="nk-block-img animated"
                  data-animate="fadeInUp"
                  data-delay=".3"
                >
                  <img src={CellPhoneImg} alt="app" />
                </div>
              </div>
              <div className="col-sm-10 col-md-4 col-lg-4">
                <div className="nk-block-text ml-lg-3 ml-xl-0">
                  <p
                    className="animated"
                    data-animate="fadeInUp"
                    data-delay=".4"
                  >
                    “PointGenie” is a revolutionary place finder app to help you
                    discover and browse through different places nearby. You can
                    find places based on your preferences. It’s like having a
                    genie on the palm of your hand that fulfils your wish when
                    you’d like to hang out nearby.
                  </p>
                  <p
                    className="animated"
                    data-animate="fadeInUp"
                    data-delay=".4"
                  >
                    All places on the app are curated by humans and you can find
                    like a traffic lights added to each place on the app.
                    Different colours are available for different places vibes
                    to suggest how likely you’re to have a good experience at
                    the place.
                  </p>

                  <p
                    className="animated"
                    data-animate="fadeInUp"
                    data-delay=".4"
                  >
                    Three colours advise the atmosphere vibes and the perception
                    of the user who visited the place most recently.
                  </p>
                  <ul className="list list-dot pdb-r">
                    <li
                      className="animated"
                      data-animate="fadeInUp"
                      data-delay=".5"
                    >
                      Venue ambience vibes
                    </li>
                    <li
                      className="animated"
                      data-animate="fadeInUp"
                      data-delay=".55"
                    >
                      Identify crowdsize
                    </li>
                    <li
                      className="animated"
                      data-animate="fadeInUp"
                      data-delay=".65"
                    >
                      Be advise about waiting times
                    </li>
                    <li
                      className="animated"
                      data-animate="fadeInUp"
                      data-delay=".7"
                    >
                      Meet and connect with people
                    </li>
                    <li
                      className="animated"
                      data-animate="fadeInUp"
                      data-delay=".75"
                    >
                      Get rewarded for all activities
                    </li>
                  </ul>
                  <ul className="btn-grp justify-content-center justify-content-md-start">
                    <li
                      className="animated"
                      data-animate="fadeInUp"
                      data-delay=".85"
                    >
                      <a
                        href="https://pointgenie.page.link/pointgeniecoin"
                        className="btn btn-grad btn-round btn-md"
                      >
                        Get the app now
                      </a>
                    </li>
                    <li
                      className="animated"
                      data-animate="fadeInUp"
                      data-delay=".95"
                    >
                      <ul className="btn-grp gutter-15px">
                        <li>
                          <a href="https://pointgenie.page.link/pointgeniecoin">
                            <em className="fab fa-apple"></em>
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SectionMeetPointGenieApp;
