import { React } from "react";
import GfxZB from "../../assets/front-imgs/light/gfx-z-b.png";

const SectionGenieVerse = () => {
  return (
    <>
      <section className="section bg-theme-dark-alt tc-light py-0 ov-h">
        <div className="container">
          <div className="nk-block nk-block-features-2">
            <div className="row align-items-center gutter-vr-30px justify-content-center justify-content-md-between">
              <div className="col-mb-9 col-sm-7 col-md-8 col-lg-8">
                <div
                  className="py-4 animated"
                  data-animate="fadeInUp"
                  data-delay=".1"
                >
                  <img src={GfxZB} alt="gfx" />
                </div>
              </div>
              <div className="col-sm-10 col-md-4 text-center text-md-left">
                <div className="nk-block-text">
                  <h4
                    className="title title-lg animated"
                    data-animate="fadeInUp"
                    data-delay=".2"
                  >
                    What is the GenieVerse?
                  </h4>
                  <p
                    className="lead animated"
                    data-animate="fadeInUp"
                    data-delay=".3"
                  >
                    We are a functional and fully operable Location-Based app
                    from the Real-World coexisting with a parallel Universe
                    running on the Ethereum Network
                  </p>
                  <p
                    className="animated"
                    data-animate="fadeInUp"
                    data-delay=".4"
                  >
                    NFT’s - GenieVerse allows anyone to own Real-World Locations
                    in our GenieVerse and get rewarded for the interaction of
                    users from the real world through the PointGenie app.
                  </p>
                  <p
                    className="animated"
                    data-animate="fadeInUp"
                    data-delay=".5"
                  >
                    Users from PointGenie App generate Genie Points by
                    interacting with real-world locations and doing all their
                    normal social media activity. Later GeniePoints are
                    interchangeable by GenieCoin tradable on exchanges and
                    becoming FIAT money. Everything unified with a blockchain
                    ecosystem, which will make business truly efficient,
                    transparent, and reliable.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SectionGenieVerse;
